import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Helmet } from "react-helmet";
import "./root.css";
import "fontsource-libre-franklin/300.css";
import "fontsource-libre-franklin/400.css";
import "fontsource-libre-franklin/500.css";
import "fontsource-libre-franklin/700.css";
import "fontsource-merriweather/300.css";
import "fontsource-merriweather/400.css";
import "fontsource-merriweather/700.css";

const sanSerifFontFamily = [
    '"Libre Franklin"',
    '"Segoe UI"',
    '"Helvetica"',
    '"Roboto"',
    '"Arial"',
    '"sans-serif"',
].join(",");

const serifFontFamily = [
    '"Merriweather"',
    '"Georgia"',
    '"Times New Roman"',
    '"Times"',
    '"serif"',
].join(",");

const headingMargin = "0 0 0.75em 0";

const primary = "#1976d2";
const primaryLight = "#63a4ff";
const primaryDark = "#004ba0";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: primary,
            light: primaryLight,
            dark: primaryDark,
        },
    },
    typography: {
        fontFamily: sanSerifFontFamily,
        body2: {
            fontFamily: serifFontFamily,
            fontSize: "1.125rem",
            lineHeight: "1.875rem",
        },
        // button: {
        //     textTransform: "none",
        // },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    fontSize: "1.125rem",
                    lineHeight: "1.875rem",
                    fontFamily: serifFontFamily,
                    backgroundColor: "#fff",
                },
                h1: {
                    fontSize: "2.5rem", // 40px
                    lineHeight: "3rem", // 48px
                    fontFamily: sanSerifFontFamily,
                    margin: headingMargin,
                },
                h2: {
                    fontSize: "1.75rem", // 28px
                    lineHeight: "2.125rem", //34px,
                    fontFamily: sanSerifFontFamily,
                    margin: headingMargin,
                },
                h3: {
                    fontFamily: sanSerifFontFamily,
                    margin: headingMargin,
                },
                h4: {
                    margin: headingMargin,
                },
                h5: {
                    margin: headingMargin,
                },
                h6: {
                    margin: headingMargin,
                },
                p: {
                    marginTop: 0,
                    marginBottom: "1.5rem",
                },
                a: {
                    color: primary,
                    backgroundColor: "transparent",
                    "&:hover": {
                        color: "rgba(0,0,0,0.85)",
                    },
                },
            },
        },
    },
});

interface RootLayoutProps {
    children?: React.ReactNode;
}

const Layout: React.FC<RootLayoutProps> = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                /> */}
                {/* <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Merriweather:300,400,500,700|Libre+Franklin:300,400,500,700&display=swap"
                /> */}
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </React.Fragment>
    );
};

export default Layout;
